@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Quicksand", sans-serif;
}
.css-1tnmulc-MuiTableCell-root {
  color: #1c4588 !important;
  font-weight: bold !important;
}
.css-1vsxwu9-MuiTableCell-root-MuiTablePagination-root {
  border: none !important;
}
.css-yv48m4 .MuiDrawer-root .MuiButtonBase-root {
  margin-top: 15px;
}
.css-1bq0eiw .MuiDrawer-root .MuiButtonBase-root {
  margin-top: 15px;
}
.navbar .MuiFormControl-root {
  justify-content: center;
  align-items: center;
}
.navbar .MuiInputBase-root {
  width: 60%;
}
input:focus-visible {
  outline: none;
  border-bottom: 1px solid #ccc;
  border-top: none;
  border-left: none;
  border-right: none;
}
input:focus {
  border-bottom: 1px solid #ccc;
  border-top: none;
  border-left: none;
  border-right: none;
}
input:active {
  border-bottom: 1px solid #ccc;
  border-top: none;
  border-left: none;
  border-right: none;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.initialContactAccordion .MuiAccordion-root {
  margin-left: -16px !important;
}
.addAppointmentAccordion .MuiGrid-item {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.sticky-last-column:last-child {
  position: sticky;
  left: 0;
  right: 0;
  background-color: #fff;
  border-left: 1px solid #ccc;
}
.DischargeReport-parent .sticky-last-column:last-child {
  position: static !important;
}
.MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:last-child {
  background-color: #f3f5f7;
}

.MuiFormHelperText-contained {
  margin-left: 3px !important;
}
.preview_file:hover {
  color: #1c4588 !important;
}

/*Table Css in Design Care Plan*/

.designCareTable table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  overflow-x: scroll;
}

.designCareTable td,
.designCareTable th {
  width: 100%;
  border-spacing: 0;
  height: 30px;
  width: 50%;
  background: white;
  border: 1px solid #e2e8f0;
}
.second td,
.second th {
  height: 36px;
  width: 25%;
  background: white;
}
.designCareTable td {
  border-style: none solid solid none;
  border-collapse: collapse;
}
.designCareTable th {
  border-style: solid solid none none;
  border-collapse: collapse;
}
.second th:nth-child(1) {
  border-style: solid solid none solid;
  border-collapse: collapse;
}
.second th:not(1) {
  border-style: solid solid none none;
  border-collapse: collapse;
}
.designCareTable tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
.designCareTable tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.designCareTable tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.designCareTable tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.designCareTable tr:first-child td {
  border-top-style: solid;
}
.designCareTable tr td:first-child {
  border-left-style: solid;
}

@media screen and (min-width: 300px) and (max-width: 638px) {
  .AreaChartClass {
    overflow-x: auto;
  }
  .lineChartClass {
    overflow-x: auto;
  }
}
@media screen and (min-width: 639px) and (max-width: 1023px) {
  .cardClass {
    min-height: 200px !important;
  }
  .AreaChartClass {
    overflow-x: auto;
  }
  .lineChartClass {
    overflow-x: auto;
  }
}

.reportTable tr td {
  padding: 15px !important;
}
.reportTable tr td h2 {
  margin: 0px 0px;
}
.reportTable tr td h4 {
  margin: 0px 0px;
}
.reportTable tr td h6 {
  margin: 0px 0px;
}

.bgClass {
  background-color: #1c4588;
}
/* ProgressBar.css */
.progress-bar {
  width: 100%;
  height: 13px;
  background-color: #845adf;
  border-radius: 10px;
  margin-top: 10px;
  position: relative;
}

.progress-purple {
  height: 100%;
  background-color: #49b6f5;
  border-radius: 10px;
}

.progress-bar1 {
  width: 100%;
  height: 13px;
  background-color: #26bf94;
  border-radius: 10px;
  margin-top: 10px;
  position: relative;
}

.progress-green {
  height: 100%;
  background-color: #f5b849;
  border-radius: 10px;
}

.profile_dialog {
  padding-top: 55px !important;
}

/* this is for temporary */
.funnelClass .recharts-legend-item.legend-item-0 {
  display: none !important;
}
.container_class:last-child {
  border-bottom: none;
  padding-bottom: 0 !important;
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .cardClass {
    min-height: 550px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .AreaChartClass {
    overflow-x: auto;
  }
  .lineChartClass {
    overflow-x: auto;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .cardClass {
    min-height: 590px !important;
  }
}

@media screen and (min-width: 1441px) {
  .cardClass {
    min-height: 585px !important;
  }
}

.legend {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0px;
  line-height: 25px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 17px;
  font-size: 14px;
  font-weight: normal;
  flex-wrap: wrap;
}

.legend-color {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  border-radius: 50%;
}

.line-with-shadow {
  stroke: #8884d8; /* Line color */
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1)); /* Box shadow */
}
